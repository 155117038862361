import { styled } from "../../../stitches.config"

export const GrayContainer = styled("div", {
  length: 0,
  display: "block",
  backgroundColor: "$slate4",
  borderRadius: "10px",
  maxWidth: "640px",
  color: "$slate11",
  fontSize: "$sm",
  lineHeight: "$500",
  listStyleType: "disc",
  marginBottom: "$10",
  marginTop: "$2",
  marginRight: "$2",
  paddingRight: "$6",
  paddingLeft: "$8",
  paddingTop: "$3",
  paddingBottom: "$0",
})
