import React from 'react';
import { Linkedin, GitHub, Gitlab } from 'react-feather';

const Button = ({ href, Icon }) => (
  <li style={{display: "inline-block", margin: '3vw 1.2vw 0 1.5vw'}}>
    <a
      href={href}
      style={{
        display: 'inline-block',
        backgroundColor: 'var(--colors-slate3)',
        borderRadius: '8px',
        padding: '0.75rem',
        transition: 'background-color 0.2s ease-in-out',
        cursor: 'pointer'
      }}
      onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = 'var(--colors-slate6)')}
      onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'var(--colors-slate3)')}
    >
      <Icon strokeWidth={0.8} size={70}/>
    </a>
  </li>
);

export const ShareButtons = () => (
  <div className="post-meta-share-icons" >
    <ul style={{display: 'flex', listStyle: 'none'}}>
      <Button href="https://www.linkedin.com/in/matheus-tura/" Icon={Linkedin} />
      <Button href="https://github.com/zatura" Icon={GitHub} />
      <Button href="https://gitlab.com/matheus-tura" Icon={Gitlab} />
    </ul>
  </div>
);
